.listpeople {

    height: 100%;
    width: 100%;
    align-self: flex-start;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;

}

.list {

    height: 90%;
    width: 90%;
    margin: 0% 5%;
    align-self: flex-start;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #726468;

}

.title {

    justify-self: flex-start;
    text-align: center;
    padding: 5px;
    margin-bottom: 15px;
    background-color: #726468;
    color: #FFF;
    width: 100%;
    font-weight: bold;

}

.selected {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    color: #FFF;
    background-color: #EDB30D;
    font-weight: bold;
    padding: 5px 5px 5px 10px;
    border-radius: 2px;
    width: 100%
}

.notselected {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
    color: #222;
    padding: 5px 5px 5px 10px;
    width: 100%
}