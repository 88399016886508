.panel {
    right: 20%;
    transition: all 0.5s ease-in-out

}

.panel-open {
    right: 2.5%;
    transition: 0.5s ease-in-out

}

.languette {
    transition: 0.5s ease-in-out
}

.languette-open {
    transition: 0.5s ease-in-out
}