.global {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 95vh;
    background-color: rgba(230,230,230,1)
}

.legend {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 8vh

}

.containerparam {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 70%;
    width: 60%;
    border-radius: 8px;
    background-color: #FFF;
    box-shadow: gray 0px 1px 10px 0px;
}