#content {
    width: 60%;
    height: 70%;
    overflow-y: scroll; /* Adding the ability to scroll */
  }
  
  /* Hiding scrollbar for Chrome, Safari and Opera */
  #content::-webkit-scrollbar {
      display: none;
  }
  
  /* Hiding scrollbar for IE, Edge and Firefox */
  #content {
    scrollbar-width: none;  /* Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
  }