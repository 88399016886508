.fond {
    position: absolute;
    background-color: #FFF;
    width: 100%;
    height: 100%;
    border-left: 2px solid #222;
    border-bottom: 2px solid #222;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0%;
    grid-auto-rows: minmax(25%, auto);
}


.board {
    position: absolute;
    width: 100%;
    height: 100%;
    border-left: 2px solid #222;
    border-bottom: 2px solid #222;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-gap: 0%;
}

.point:hover{
    opacity: 0.8;
    z-index: 1000;
}

.point[name][etat][maitrise][probabilité][impact]:hover::before {
    content: attr(name) ' ' attr(etat) ' : Probabilité : 'attr(probabilité) ' - Impact : 'attr(impact) ' - Maîtrise : 'attr(maitrise);
    text-align: justify;
    position: absolute;
    left: -90%;
    top: -100px;
    width:max-content;
    max-width: 300%;
    height: max-content;
    border-radius: 10px;
    background-color: #222;
    opacity: 0.8;
    padding: 12px;
    color: #FFF;
    font-size: 14px;
    z-index: 1000;
}

.pointsup:hover{
    text-align: center;
    font-weight: bold;
    z-index: 1000;
    background-color: #FFF;
    border-radius: 3px;
    outline: 1px solid #222  
}

.pointsimple:hover{
    outline: 1px solid #222
}

.pointsup[name][etat][maitrise][probabilité][impact][code]:hover::before {
    content: attr(code) ' - ' attr(name) ' ' attr(etat) ' : Probabilité : 'attr(probabilité) ' - Impact : 'attr(impact) ' - Maîtrise : 'attr(maitrise);
    text-align: justify;
    position: absolute;
    left: -90%;
    top: -100px;
    width:max-content;
    max-width: 300px;
    height: max-content;
    border-radius: 10px;
    background-color: #222;
    opacity: 0.8;
    padding: 12px;
    color: #FFF;
    font-size: 14px;
    z-index: 1000;
}

.one {
    background-color: #8ac926;
    grid-column: 1;
    grid-row: 4;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.two {
    background-color: #8ac926;
    grid-column: 1;
    grid-row: 3;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.three {
    background-color: #8ac926;
    grid-column: 2;
    grid-row: 4;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.four {
    background-color: yellow;
    grid-column: 1;
    grid-row: 2;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.five {
    background-color: yellow;
    grid-column: 2;
    grid-row: 3;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.six {
    background-color: yellow;
    grid-column: 3;
    grid-row: 4;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.seven {
    background-color: orange;
    grid-column: 2;
    grid-row: 2;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.eight {
    background-color: orange;
    grid-column: 3;
    grid-row: 3;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.nine {
    background-color: orange;
    grid-column: 1;
    grid-row: 1;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.ten {
    background-color: #fb5607;
    grid-column: 2;
    grid-row: 1;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.eleven {
    background-color: #fb5607;
    grid-column: 3;
    grid-row: 2;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.twelve {
    background-color: #fb5607;
    grid-column: 4;
    grid-row: 3;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.thirteen {
    background-color: orange;
    grid-column: 4;
    grid-row: 4;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.fourteen {
    background-color: red;
    grid-column: 3;
    grid-row: 1;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.fifteen {
    background-color: red;
    grid-column: 4;
    grid-row: 1;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.sixteen {
    background-color: red;
    grid-column: 4;
    grid-row: 2;
    opacity: 0.8;
    border: 0.1px solid #FFF
}




.cell11 { grid-column: 1; grid-row: 6; display: flex; flex-direction: column; justify-content: center; align-items: center;}
.cell12 { grid-column: 2; grid-row: 6; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell13 { grid-column: 3; grid-row: 6; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell14 { grid-column: 4; grid-row: 6; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell15 { grid-column: 5; grid-row: 6; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell16 { grid-column: 6; grid-row: 6; display: flex; flex-direction: column; justify-content: center; align-items: center; }

.cell21 { grid-column: 1; grid-row: 5; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell22 { grid-column: 2; grid-row: 5; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell23 { grid-column: 3; grid-row: 5; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell24 { grid-column: 4; grid-row: 5; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell25 { grid-column: 5; grid-row: 5; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell26 { grid-column: 6; grid-row: 5; display: flex; flex-direction: column; justify-content: center; align-items: center; }

.cell31 { grid-column: 1; grid-row: 4; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell32 { grid-column: 2; grid-row: 4; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell33 { grid-column: 3; grid-row: 4; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell34 { grid-column: 4; grid-row: 4; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell35 { grid-column: 5; grid-row: 4; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell36 { grid-column: 6; grid-row: 4; display: flex; flex-direction: column; justify-content: center; align-items: center; }

.cell41 { grid-column: 1; grid-row: 3; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell42 { grid-column: 2; grid-row: 3; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell43 { grid-column: 3; grid-row: 3; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell44 { grid-column: 4; grid-row: 3; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell45 { grid-column: 5; grid-row: 3; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell46 { grid-column: 6; grid-row: 3; display: flex; flex-direction: column; justify-content: center; align-items: center; }

.cell51 { grid-column: 1; grid-row: 2; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell52 { grid-column: 2; grid-row: 2; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell53 { grid-column: 3; grid-row: 2; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell54 { grid-column: 4; grid-row: 2; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell55 { grid-column: 5; grid-row: 2; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell56 { grid-column: 6; grid-row: 2; display: flex; flex-direction: column; justify-content: center; align-items: center; }

.cell61 { grid-column: 1; grid-row: 1; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell62 { grid-column: 2; grid-row: 1; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell63 { grid-column: 3; grid-row: 1; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell64 { grid-column: 4; grid-row: 1; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell65 { grid-column: 5; grid-row: 1; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell66 { grid-column: 6; grid-row: 1; display: flex; flex-direction: column; justify-content: center; align-items: center; }




.oner {
    background-color: #FFD9F5;
    grid-column: 1;
    grid-row: 4;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.twor {
    background-color: #FFD9F5;
    grid-column: 1;
    grid-row: 3;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.threer {
    background-color: #FF9BE5;
    grid-column: 2;
    grid-row: 4;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.fourr {
    background-color: #FF9BE5;
    grid-column: 1;
    grid-row: 2;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.fiver {
    background-color: #FF9BE5;
    grid-column: 2;
    grid-row: 3;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.sixr {
    background-color: #FF0DC0;
    grid-column: 3;
    grid-row: 4;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.sevenr {
    background-color: #FF0DC0;
    grid-column: 2;
    grid-row: 2;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.eightr {
    background-color: #FF0DC0;
    grid-column: 3;
    grid-row: 3;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.niner {
    background-color: #FF9BE5;
    grid-column: 1;
    grid-row: 1;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.tenr {
    background-color: #FF0DC0;
    grid-column: 2;
    grid-row: 1;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.elevenr {
    background-color: #C80094;
    grid-column: 3;
    grid-row: 2;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.twelver {
    background-color: #C80094;
    grid-column: 4;
    grid-row: 3;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.thirteenr {
    background-color: #C80094;
    grid-column: 4;
    grid-row: 4;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.fourteenr {
    background-color: #C80094;
    grid-column: 3;
    grid-row: 1;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.fifteenr {
    background-color: #7E005D;
    grid-column: 4;
    grid-row: 1;
    opacity: 0.8;
    border: 0.1px solid #FFF
}

.sixteenr {
    background-color: #C80094;
    grid-column: 4;
    grid-row: 2;
    opacity: 0.8;
    border: 0.1px solid #FFF
}