.back-true {
    width: 2.5vw;
    height: auto;
    cursor: pointer;
    background-color: #554348;
    border-radius: 30px;
    margin: 10px;
    padding: 3px;
    box-shadow: gray 0px 1px 10px 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.back-false {
    width: 2.5vw;
    height: auto;
    cursor: pointer;
    background-color: #ccc;
    border-radius: 30px;
    margin: 10px;
    padding: 3px;
    box-shadow: gray 0px 1px 10px 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.false {
    height: 2vh;
    width: 1vw;
    text-align: center;
    cursor: pointer;
    background-color: #FFF;
    border-radius: 50%;
    transition: all 0.3s linear;
    transform: translateX(0);
    box-shadow: #FFF 0px 1px 10px 0px;
}

.true {
    height: 2vh;
    width: 1vw;
    text-align: center;
    cursor: pointer;
    background-color: #FFF;
    border-radius: 50%;
    transition: all 0.3s linear;
    transform: translateX(1.20vw);
    box-shadow: #554348 0px 1px 10px 0px;
}