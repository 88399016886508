.subcontainercreate {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 45%;
    margin: 10px;
    border-radius: 8px;
    background-color: #FFF;
    height: 95%

}

.prio:hover {

    border: 2px solid #EEE;

}