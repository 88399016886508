.invisible {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 8vh;
    max-height: 16vh;
    width: 15vw;
    margin: 50px
}

.text-italic-inline {
    width: 100%;
    font-style: italic;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-size: 12px;
    margin-bottom: 20px;
}

.triangle {
    transform: rotate(45deg);
    position: absolute;
    height: 0vh;
    width: 0vw;
    top:-1vh;
    right:2vw;
    border-width: 10px 0px 0px 0px; /* top right bottom left */
    border-style: solid solid solid solid;
    border-color: rgba(255, 255, 255, 0.9);
}

.hide {
    position: absolute;
    max-height: 24vh;
    max-width: 20vw;
    top: -50vh;
    right: 2vw;
    transition: all 0.2s linear;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 5px;
    box-shadow: gray 0px 1px 10px 0px;
}

.show {
    position: absolute;
    max-height: 24vh;
    max-width: 20vw;
    top: 8vh;
    right: 2vw;
    transition: all 0.2s linear;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 5px;
    box-shadow: gray 0px 1px 10px 0px;
}

.mdp:hover{
    text-decoration: underline;
}
