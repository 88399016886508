.fond {
    position: absolute;
    background-color: #FFF;
    width: 100%;
    height: 100%;
    border-left: 2px solid #222;
    border-bottom: 2px solid #222;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0%;
    grid-auto-rows: minmax(25%, auto);
}


.board {
    position: absolute;
    width: 100%;
    height: 100%;
    border-left: 2px solid #222;
    border-bottom: 2px solid #222;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-gap: 0%;

}

.pointm:hover{
    text-align: center;
    font-weight: bold;
    z-index: 1000;
    background-color: #FFF;
    border-radius: 3px;
    outline: 1px solid #AAA 
}

.pointmm:hover{
    text-align: center;
    z-index: 1000;
    background-color: #FFF;
    border-radius: 3px; 
}

.pointm[crit][name][maitrise][probabilité][impact][code]:hover::after {
    content: attr(code) ' - ' attr(name) ' : Criticité : ' attr(crit) ' - Maîtrise : ' attr(maitrise) ' - Probabilité : 'attr(probabilité) ' - Impact : 'attr(impact);
    text-align: justify;
    position: absolute;
    left: -50%;
    top: -100px;
    width:max-content;
    height: max-content;
    border-radius: 10px;
    background-color: #222;
    opacity: 1;
    max-width: 300px;
    padding: 12px;
    color: #FFF;
    font-size: 14px;
    z-index: 1000;
}

.onem {
    background-color: green;
    grid-column: 1/3;
    grid-row: 3/5;
    opacity: 0.1
}

.twom {
    background-color: red;
    grid-column: 1/3;
    grid-row: 1/3;
    opacity: 0.3
}

.threem {
    background-color: red;
    grid-column: 3/5;
    grid-row: 1/3;
    opacity: 0.2
}

.fourm {
    background-color: green;
    grid-column: 3/5;
    grid-row: 3/5;
    opacity: 0.2
}

.cell11 { grid-column: 1; grid-row: 6; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell12 { grid-column: 2; grid-row: 6; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell13 { grid-column: 3; grid-row: 6; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell14 { grid-column: 4; grid-row: 6; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell15 { grid-column: 5; grid-row: 6; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell16 { grid-column: 6; grid-row: 6; display: flex; flex-direction: column; justify-content: center; align-items: center; }

.cell21 { grid-column: 1; grid-row: 5; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell22 { grid-column: 2; grid-row: 5; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell23 { grid-column: 3; grid-row: 5; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell24 { grid-column: 4; grid-row: 5; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell25 { grid-column: 5; grid-row: 5; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell26 { grid-column: 6; grid-row: 5; display: flex; flex-direction: column; justify-content: center; align-items: center; }

.cell31 { grid-column: 1; grid-row: 4; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell32 { grid-column: 2; grid-row: 4; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell33 { grid-column: 3; grid-row: 4; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell34 { grid-column: 4; grid-row: 4; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell35 { grid-column: 5; grid-row: 4; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell36 { grid-column: 6; grid-row: 4; display: flex; flex-direction: column; justify-content: center; align-items: center; }

.cell41 { grid-column: 1; grid-row: 3; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell42 { grid-column: 2; grid-row: 3; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell43 { grid-column: 3; grid-row: 3; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell44 { grid-column: 4; grid-row: 3; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell45 { grid-column: 5; grid-row: 3; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell46 { grid-column: 6; grid-row: 3; display: flex; flex-direction: column; justify-content: center; align-items: center; }

.cell51 { grid-column: 1; grid-row: 2; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell52 { grid-column: 2; grid-row: 2; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell53 { grid-column: 3; grid-row: 2; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell54 { grid-column: 4; grid-row: 2; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell55 { grid-column: 5; grid-row: 2; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell56 { grid-column: 6; grid-row: 2; display: flex; flex-direction: column; justify-content: center; align-items: center; }

.cell61 { grid-column: 1; grid-row: 1; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell62 { grid-column: 2; grid-row: 1; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell63 { grid-column: 3; grid-row: 1; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell64 { grid-column: 4; grid-row: 1; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell65 { grid-column: 5; grid-row: 1; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.cell66 { grid-column: 6; grid-row: 1; display: flex; flex-direction: column; justify-content: center; align-items: center; }