.panelitem {
    z-index: 1;
    position: fixed;
    right: 1%;
    bottom: -100%;
    height: 0%;
    overflow: hidden;
    width: 30%;
    transition: all 0.3s ease-in-out;
    background-color: #FFF;
    border-bottom: 2px solid #EDB30D 
}

.panelitem-open {
    z-index: 1;
    position: fixed;
    right: 1%;
    bottom: 1%;
    height: 40%;
    width: 30%;
    transition: all 0.3s ease-in-out;
    background-color: #FFF;
    border-bottom: 2px solid #EDB30D 
}