.trash {

    color: #CCC

}

.trash:hover {

    color: #222

}