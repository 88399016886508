.but {
    text-align: center;
    cursor: pointer;
    background-color: #EDB30D;
    color: #333;
    font-weight: bold;
    padding: 5px;
    width: 100%;
    border-radius: 3px;
    transition: all 0.3s linear
}

.but:hover {
    text-align: center;
    cursor: pointer;
    background-color: #554348;
    color: #FFF;
    font-weight: bold;
    padding: 5px;
    width: 100%;
    border-radius: 3px;
    transition: all 0.3s linear
}

.but-light {
    text-align: center;
    cursor: pointer;
    background-color: #FEF375;
    color: #333;
    font-weight: bold;
    padding: 5px;
    width: 100%;
    border-radius: 3px;
    transition: all 0.3s linear
}

.but-light:hover {
    text-align: center;
    cursor: pointer;
    background-color: #554348;
    color: #FFF;
    font-weight: bold;
    padding: 5px;
    width: 100%;
    border-radius: 3px;
    transition: all 0.3s linear
}

.but-cancel {
    text-align: center;
    cursor: pointer;
    background-color: #DDD;
    color: #333;
    font-weight: bold;
    padding: 5px;
    width: 100%;
    border-radius: 3px;
    transition: all 0.3s linear
}

.but-cancel:hover {
    text-align: center;
    cursor: pointer;
    background-color: #554348;
    color: #FFF;
    font-weight: bold;
    padding: 5px;
    width: 100%;
    border-radius: 3px;
    transition: all 0.3s linear
}

.but-dark {
    text-align: center;
    cursor: pointer;
    background-color: #554348;
    color: #FFF;
    font-weight: bold;
    padding: 5px;
    width: 100%;
    border-radius: 3px;
    transition: all 0.3s linear
}

.but-dark:hover {
    text-align: center;
    cursor: pointer;
    background-color: #EDB30D;
    color: #FFF;
    font-weight: bold;
    padding: 5px;
    width: 100%;
    border-radius: 3px;
    transition: all 0.3s linear
}