.band {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #EDB30D;
    font-family: 'Book Antiqua';
    box-shadow: gray 0px 1px 10px 0px;
}

.avatar {
    max-height: 2.6vh;
    border-radius: 50%;
    margin-right: 10px;
}

.avatar-right {
    max-height: 2.6vh;
    border-radius: 50%;
    margin-left: 10px;
}

.menuitem {
    line-height: 5vh;
    text-align: end;
    position: relative;
    min-width: 6vw;
    height: 5vh;
    z-index: 1;
    cursor: pointer;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: #FFF;
    font-weight: bold;
    margin: 0px 80px 
}

.bubble {
    position: relative;
    z-index: -1;
    cursor: pointer;
    background-color: #FFF;
    position:absolute;
    top:-30vh;
    left:-10vw;
    width: 30vw;
    height: 45vh;
    border-radius: 50%;
    transition: all 0.2s linear;

}

.linkh {
    position: relative 
}

.linkh:hover {
    cursor: pointer;
    border-radius: 50%;
    transform-origin: 0 0;
    transition: all 0.1s linear;
    transform: scale(1.03);
}


