.listpeople {

    height: 100%;
    width: 100%;
    align-self: center;
    background-color: #FFF;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;

}

.list {

    height: 90%;
    margin: 0% 5%;
    align-self: flex-start;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.title {

    justify-self: flex-start;

    text-align: center;
    padding: 5px;
    margin-bottom: 15px;
    background-color: #EDB30D;
    color: #FFF;
    width: 100%;
    font-weight: bold;

}

.selected {

    color: #FFF;
    background-color: #EDB30D;
    font-weight: bold;
    padding: 0px 5px;
    border-radius: 5px;

}

.notselected {
    cursor: pointer;
    color: #222;
}

.notselected:hover {
    cursor: pointer;
    color: #222;
    background-color: #FFFEEE
}


.url[name] {
    color: #222
}

.url[name]:hover {
    color: #EDB30D;
}

.url[name]:hover::after {
    content: 'Cliquer pour ouvrir dans une nouvelle fenêtre le document ' attr(name);
    text-align: justify;
    position: absolute;
    left: 0px;
    bottom: 0px;
    border: 1px #EDB30D solid;
    border-radius: 10px;
    background-color: #fffccc;
    padding: 12px;
    color: #000000;
    font-size: 14px;
    z-index: 1;
}