.global {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(230,230,230,1)
}

.legend {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 8vh

}

.tag-top {
    padding: 5px;
    margin: 5px; 
    border-radius: 5px;
    background-color: #FFF;
    color: #222;
    font-size: 14;
    font-weight: bolder;

}

.tag-low {
    cursor: pointer;
    padding: 3px 25px; 
    margin: 2px 0px 2px 30px;
    border-radius: 5px;
    background-color: #FFF;
    color: #222;
    font-size: 12;
    transition: all 0.3s ease-in-out;
    width: 87%;
}

.tag-low:hover {
    cursor: pointer;
    padding: 3px 25px; 
    margin: 2px 0px 2px 30px; 
    border-radius: 5px;
    background-color: #EEE;
    color: #222;
    font-size: 12;
    transition: all 0.1s ease-in-out;
    width: 87%;
}

.tag-low-selected {
    cursor: pointer;
    padding: 3px 25px;
    font-weight: bold; 
    margin: 2px 0px 2px 30px; 
    border-radius: 5px;
    background-color: #EDB30D;
    color: #222;
    font-size: 12;
    transition: all 0.3s ease-in-out
}

.tag-middle {
    font-weight: bold;
    padding: 5px; 
    margin: 5px 0px 5px 25px; 
    border-radius: 5px;
    background-color: #FFF;
    color: #EDB30D;
    font-size: 13;
}

.containersuivi {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 70%;
    width: 60%;
    border-radius: 8px;
    background-color: #FFF;
    padding: 30px 0px;
    z-index: 3
}

.subcontainersuivi {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 45%;
    margin: 10px;
    border-radius: 8px;
    background-color: #FFF;
    height: 80%
}

.containersuivi-small-left {
    position: absolute;
    left: 2.5%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 60%;
    width: 15%;
    border-radius: 8px;
    background-color: #FFF;
    opacity: 0.8;
}

.containersuivi-small-right {
    position: absolute;
    right: 2.5%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 60%;
    width: 15%;
    border-radius: 8px;
    background-color: #FFF;
    opacity: 0.8;
}

.card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

}

.containersuivi-scrollable {

    overflow-y: auto;
    width: 95%

}

.field {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 2px solid #EDB30D;
    width: 100%;
    border-radius: 3px;
    margin: 0px;
    max-height: 30%;
    overflow-y: auto;
}

.title-field {
    position: sticky;
    top: 0;
    left: 0;
    background-color: #EDB30D;
    color: #FFF;
    font-weight: bold;
    width: 100%;
    padding: 5px 5px 5px 15px;
}

.content {

    padding: 10px 10px 10px 15px;
    margin-block-end: 0px;

}

.score {
    font-weight: bolder;
    font-size: larger;
    text-align: center;
    line-height: 4vh;
    width: 8vw;
    padding-right: 5px;
    height: 4vh;  
    background-color: #DDD;
    border-radius: 0px 50px 50px 0px
}

.lib-score {
    font-weight: bold;
    color: #FFF;
    line-height: 4vh;
    text-align: center;
    width: 20vw;
    height: 4vh;  
    background-color: #EDB30D;
    border-radius: 50px 0px 0px 50px
}

.buttonbackstyle {

    min-width: 5vw;
    height: 4vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #554348;
    border-radius: 30px;
    margin: 20px;
    box-shadow: gray 0px 1px 10px 0px;
    
}

.buttonstyle {
    text-align: center;
    line-height: 2vh;
    cursor: pointer;
    color: #FFF;
    font-weight: bold;
    min-width: 5vw;
    background-color: #554348;
    border-radius: 50px;
    padding: 5px;
    margin: 5px;
    transition: all 0.3s linear;
}

.buttonstyle:hover {
    text-align: center;
    line-height: 2vh;
    cursor: pointer;
    font-weight: bold;
    color: #444;
    min-width: 5vw;

    background-color: #FFF;
    border-radius: 50px;
    padding: 5px;
    margin: 5px;
    transition: all 0.3s linear;
}

.versions {
    font-size:13px;
    cursor: pointer;
    text-align: center;
    padding: 5px;
}

.versions:hover {
    font-size:13px;
    cursor: pointer;
    text-align: center;
    background-color: #554348;
    color: #FFF;
    font-weight: bold;
    padding: 5px;
    border-radius: 5px;
}


.slider {
    transition: all 0.2s linear;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    bottom: -600px;
    background-color: white;
    width: 60%;
    height: 0%;
    overflow: hidden
}

.slider-open {
    transition: all 0.2s linear;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    bottom: 14.5%;
    background-color: white;
    width: 60%;
    height: 54.5%;
}

#content {
    overflow-y: scroll; /* Adding the ability to scroll */
  }
  
  /* Hiding scrollbar for Chrome, Safari and Opera */
  #content::-webkit-scrollbar {
      display: none;
  }
  
  /* Hiding scrollbar for IE, Edge and Firefox */
  #content {
    scrollbar-width: none;  /* Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
  }