.back {

    min-width: 10vw;
    height: 4vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #554348;
    border-radius: 30px;
    margin: 20px;
    box-shadow: gray 0px 1px 10px 0px;
    
}

.item-selected {

    text-align: center;
    line-height: 2vh;
    cursor: pointer;
    font-weight: bold;
    color: #444;
    min-width: 5vw;
    background-color: #FFF;
    border-radius: 50px;
    padding: 5px;
    margin: 5px;
    transition: all 0.3s linear;

}


.item-uns {

    text-align: center;
    line-height: 2vh;
    cursor: pointer;
    color: #FFF;
    font-weight: bold;
    min-width: 5vw;
    background-color: #554348;
    border-radius: 50px;
    padding: 5px;
    margin: 5px;
    transition: all 0.3s linear;

}