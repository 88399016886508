.first {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #F0F5FA;
}

.containerhome {
    position: relative;
    right: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 5px;
}

.containerhomechoice {
    min-height: 40vh;
    width: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
}

.logo-asterion {
    position: absolute;
    bottom: 0.5vh;
    right: 2vw
}

/* .bubble {
    cursor: pointer;
    background-color: aquamarine;
    position:absolute;
    top:-30vh;
    left:-10vw;
    width: 30vw;
    height: 50vh;
    border-radius: 50%;
    transition: all 0.2s linear;
}

.bubble:hover {
    cursor: pointer;
    background-color: aquamarine;
    position:absolute;
    top:-30vh;
    left:-10vw;
    width: 30vw;
    height: 50vh;
    border-radius: 50%;
    transition: all 0.2s linear;
    box-shadow: black 0px 1px 3px 0px;
    transform: scale(1.1);
}

.bubblet {
    cursor: pointer;
    background-color: aquamarine;
    position:absolute;
    top:-30vh;
    left:-10vw;
    width: 40vw;
    height: 60vh;
    border-radius: 50%;
    transition: all 1s linear;
    transform: scale(2);
}

.one {
    width: 100px;
    height: 50px;
    background-color: blue;
    transition: all 1s linear;
    transform-origin: 1px;
    transform: scale(1, 1.5) rotate(-90deg) 
}

.two {
    width: 100px;
    height: 50px;
    background-color: red;
    transition: all 1s linear;
    transform-origin: 1px;
    transform: scale(1, 1.5) rotate(-90deg) 
}

.onet {
    width: 100px;
    height: 50px;
    background-color: blue;
    transition: all 1s linear;
    transform-origin: 1px;
    transform: rotate(90deg) scale(1, 1.5);
  }
  
.twot {
    width: 100px;
    height: 50px;
    background-color: red;
    transition: all 1s linear;
    transform-origin: 1px;
    transform: rotate(90deg) scale(1, 1.5);
  } */