.first {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(230, 230, 230, 1);
}

.container-hidden {
    min-height: 50vh;
    width: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    opacity: 0;
    transition: transform 0.3s ease-in-out;
    transform: scale(0.2)
}

.container-shown {
    padding: 40px 0px;
    min-height: 20vh;
    width: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    opacity: 1;
    transition: transform 0.3s ease-in-out;
    transform: scale(1)
}

.text-inter {
    color: #333;
    font-weight: bolder;
    font-size: 18px;
    border-bottom:#EDB30D solid 2px;
    border-top:#EDB30D solid 2px;
    padding: 2px
}

.link {
    color: #FFF;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 4vh;
    width: 80%;
    margin: 15px;
    border-radius: 8px;
    background-color: #EDB30D;
    transition: all 0.2s linear;

}

.link:hover {
    color: #fff;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 4vh;
    width: 80%;
    border-radius: 8px;
    transition: all 0.1s linear;
    transform: scale(1.03);
    background-color: #554348;

}

.link-dark {
    color: #FFF;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 4vh;
    width: 80%;
    margin: 15px;
    border-radius: 8px;
    background-color: #554348;
    transition: all 0.2s linear;

}

.link-dark:hover {
    color: #fff;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 4vh;
    width: 80%;
    border-radius: 8px;
    transition: all 0.1s linear;
    transform: scale(1.03);
    background-color: #EDB30D;

}
